import { useQuery } from 'react-query';
import { type EventPagesResponse } from '../dtos/EventPagesDto';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useEventPages = (year?: number) => {
  return useQuery(API_URLS.EVENT_LIST(year ?? 2023), async () => {
    try {
      const response = await fetcher.get<EventPagesResponse>(API_URLS.EVENT_LIST(year ?? 2023));

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useEventPages;
