import { Box, Skeleton, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { styleCard, styleCardImageWrapper, styleTextWrapper } from './EventCard';
import { css } from '@emotion/react';
import { styleCommonCardsSection } from '.';

const SkeletonInfoCard = () => {
  return (
    <Box component={'article'} css={styleCard}>
      <Skeleton css={styleCardImageWrapper}></Skeleton>
      <Box css={styleTextWrapper}>
        <Skeleton width="100%" height={27} />
        <Skeleton width="100%" height={48} />
      </Box>
    </Box>
  );
};

const SkeletonInfoCards = () => {
  return (
    <Box component="section" css={styleCommonCardsSection}>
      <Box component="ul" css={styleList}>
        <SkeletonInfoCard />
        <SkeletonInfoCard />
        <SkeletonInfoCard />
        <SkeletonInfoCard />
        <SkeletonInfoCard />
        <SkeletonInfoCard />
      </Box>
    </Box>
  );
};

const styleList = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '64px',
  columnGap: '32px',

  margin: '40px 0 80px',
  padding: '32px',
  width: '100%',
  maxWidth: '1200px',

  [desktopFirstMediaQuery.tablet]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [desktopFirstMediaQuery.mobile]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: '48px',
    columnGap: '16px',

    margin: '20px 0 60px',
    padding: '16px'
  }
});

export default SkeletonInfoCards;
