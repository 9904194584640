import { type PageResponse } from './PageDto';
import PageDto from './PageDto';

export type EventPagesResponse = {
  ok: boolean;
  data: PageResponse[];
};

class EventPagesDto {
  readonly pages: PageDto[];
  constructor (response: EventPagesResponse) {
    this.pages = response.data.map((page) => new PageDto(page));
  }
}

export default EventPagesDto;
