import { Box } from '@inflearn/ds-react';
import { useEffect, useMemo } from 'react';
import CommonHero, { HERO_IMAGE_NAME } from '../../../../components/infcon-2023/commons/CommonHero';
import EventCards from '../../../../components/infcon-2023/commons/EventCard';
import EventPagesDto from '../../../../dtos/EventPagesDto';
import useEventPages from '../../../../hooks/useEventPages';
import { type HeadFC, navigate } from 'gatsby';
import SkeletonInfoCards from '../../../../components/infcon-2023/commons/EventCard/SkeletonInfoCards';
import Seo from '../../../../utils/Seo';
import { GATSBY_SITE_URL } from '../../../../utils/env';
import { URLS } from '../../../../utils/urls';

const Infcon2023EventPage = () => {
  const { data, isLoading, isError } = useEventPages();

  const events = useMemo(() => {
    if (isLoading || data == null) {
      return [];
    }

    return new EventPagesDto(data).pages;
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      void navigate('/404');
    }
  }, [isError]);

  return (
    <Box component="section">
      <CommonHero
        title={'인프콘 이벤트'}
        description={'더욱 풍성한 경험을 위해 준비했어요'}
        heroImageName={HERO_IMAGE_NAME.event}
      />
      {isLoading ? <SkeletonInfoCards /> : <EventCards cards={events} />}
    </Box>
  );
};

export const Head: HeadFC = () => {
  return (
    <Seo
      title="인프콘 2023 이벤트 - INFCON 2023"
      description="더욱 풍성한 경험을 위해 준비했어요 - 인프런이 만드는 IT인의 축제, 인프콘으로 초대합니다. 인프콘과 함께 배우고 나누고 성장하세요. 다양한 발표 세션, 핸즈온, 네트워킹까지 만나보세요."
      fullUrl={`${GATSBY_SITE_URL}${URLS.INFCON_2023__EVENT}`}
    />
  );
};

export default Infcon2023EventPage;
